import React, { FC, useCallback, useState } from 'react';
import {
  IonButton, IonInput, IonItem, IonLabel, IonList, IonText,
} from '@ionic/react';
import { API, graphqlOperation } from 'aws-amplify';
import dayjs from 'dayjs';
import { Redirect } from 'react-router';
import {action} from '@deptno/gtag'
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { PageLayout } from '../components/Layouts/PageLayout';
import { createRandomStrings } from '../libs/randomString';

const getDefaultRecordItem = (playerName = '', jobName = ''): string => {
  const item = {
    head: 'greed',
    body: 'greed',
    belt: 'greed',
    legs: 'greed',
    feet: 'greed',
    player_name: playerName,
    job_name: jobName,
    earrings: 'greed',
    necklace: 'greed',
    bracelet: 'greed',
    ring: 'greed',
    arm_box: 'greed',
    arm_1: 'greed',
    mount: 'greed',
  };
  return JSON.stringify(item);
};
const createNewRoomWithCheckId = async (roomName: string, roomId: string): Promise<string> => {
  const { data, errors } = await API.graphql(graphqlOperation(`query getRoom {
        getFF14LootManager(room_id: "${roomId}") {
          invite_code
          loots_dps_1
          loots_dps_2
          loots_dps_3
          loots_dps_4
          loots_healer_1
          loots_healer_2
          loots_tank_1
          loots_tank_2
          owner_id
          room_id
          room_name
          ttl
        }
      }`)) as GraphQLResult<{
          getFF14LootManager: unknown
        }
      >;
  if (errors && errors.length > 0) {
    throw new Error(errors.map((err) => err.message).join('\n'));
  }
  if (data && data.getFF14LootManager) {
    console.warn('Detect a duplicated room id, re-create it.');
    return createNewRoomWithCheckId(roomName, createRandomStrings());
  }
  const query = {
    createff14lootmanagerinput: {
      room_id: roomId,
      owner_id: 'unknown_user',
      loots_tank_1: getDefaultRecordItem(),
      loots_tank_2: getDefaultRecordItem(),
      loots_healer_1: getDefaultRecordItem(),
      loots_healer_2: getDefaultRecordItem(),
      loots_dps_1: getDefaultRecordItem(),
      loots_dps_2: getDefaultRecordItem(),
      loots_dps_3: getDefaultRecordItem(),
      loots_dps_4: getDefaultRecordItem(),
      invite_code: '',
      ttl: dayjs().add(6, 'month').unix(), // valueOf()-> msec
      room_name: roomName.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;')
        .replace(/'/g, '&#39;'),
    },
  };
  const { data: creationResult, errors: creationErrors } = await API.graphql(graphqlOperation(`mutation createFF14LootManager($createff14lootmanagerinput: CreateFF14LootManagerInput!) {
        createFF14LootManager(input: $createff14lootmanagerinput) {
          room_id
          owner_id
          loots_tank_1
          loots_tank_2
          loots_healer_1
          loots_healer_2
          loots_dps_1
          loots_dps_2
          loots_dps_3
          loots_dps_4
          invite_code
          ttl
          room_name
        }
      }`, query)) as GraphQLResult<{
        createFF14LootManager: {room_id: string}
        }
      >;
  if (creationErrors && creationErrors.length > 0) {
    throw new Error(creationErrors.map((err) => err.message).join('\n'));
  }
  if (!creationResult) return roomId;
  return creationResult.createFF14LootManager.room_id;
};

const sleep = (msec: number) => new Promise(resolve => setTimeout(resolve, msec));
const useCreateNewRoomHook = () => {
  const [progress, updateProgress] = useState<'' | 'loading' | 'complete' | 'error'>('');
  const [errorMessage, setErrorMessage] = useState('');
  const [roomId, setNewRoomId] = useState('');
  const createNewRoom = useCallback((roomName: string) => {
    updateProgress('loading');
    const roomId = createRandomStrings(16);
    try {
      createNewRoomWithCheckId(roomName, roomId)
        .then(async(roomId) => {
          await sleep(3000)
          action('user_create_new_room', {
            event_category: 'create_new_room',
            event_label: 'Create new Room',
            value:1
          })
          updateProgress('complete');
          setNewRoomId(roomId);
        }).catch((e) => {
          console.error(e);
          updateProgress('error');
          setErrorMessage(e.message);
        });
    } catch (e) {
      console.log(e);
    }
  }, [setErrorMessage, updateProgress, setNewRoomId]);

  return {
    createNewRoom,
    progress,
    errorMessage,
    roomId,
  };
};

export const PageCreateNewRoom: FC = () => {
  const [roomName, updateRoomName] = useState('');
  const {
    createNewRoom,
    progress,
    roomId,
    errorMessage,
  } = useCreateNewRoomHook();
  if (progress === 'complete' && roomId) {
    return <Redirect to={`/rooms/${roomId}`} />;
  }
  return (
    <PageLayout title="Create a new Room" headerImage>
      <IonList>
        {errorMessage ? (
          <IonItem>
            <IonText color="danger">{errorMessage}</IonText>
          </IonItem>
        ) : null}
        <IonItem>
          <IonLabel position="fixed">ルーム名</IonLabel>
          <IonInput
            value={roomName}
            placeholder="ルーム名を入力しましょう"
            onIonChange={(e) => updateRoomName(e.detail.value || '')}
          />
        </IonItem>
        <div className="ion-padding ion-text-center">
          <IonButton
            expand="block"
            disabled={!roomName}
            onClick={() => {
              if (!roomName) return;
              createNewRoom(roomName);
            }}
          >
            {progress === 'loading' ? '作成中...' : '作成'}
          </IonButton>
        </div>
      </IonList>
    </PageLayout>
  );
};
