import React, { FC } from 'react';
import {
  IonIcon,
  IonLabel,
  IonRouterOutlet, IonTabBar, IonTabButton, IonTabs,
} from '@ionic/react';
import { Route } from 'react-router';
import { home, document, informationCircle } from 'ionicons/icons';
import Home from '../pages/Home';
import { PageAbout } from '../pages/About';
import { PageCreateNewRoom } from '../pages/CreateNewRoom';

export const RouteMain: FC = () => (
  <>
    <IonTabs>
      <IonRouterOutlet>
        <Route path="/create_new_room" component={PageCreateNewRoom} exact />
        <Route path="/about" component={PageAbout} />
        <Route path="/" component={Home} exact />
      </IonRouterOutlet>
      <IonTabBar slot="bottom">
        <IonTabButton tab="home" href="/">
          <IonIcon icon={home} />
          <IonLabel>Home</IonLabel>
        </IonTabButton>
        <IonTabButton tab="create_new_room" href="/create_new_room">
          <IonIcon icon={document} />
          <IonLabel>新しい固定</IonLabel>
        </IonTabButton>
        <IonTabButton tab="about" href="/about">
          <IonIcon icon={informationCircle} />
          <IonLabel>About</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  </>
);
