import React, { FC, PropsWithChildren, ReactNode } from 'react';
import {
  IonBackButton, IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar,
} from '@ionic/react';

export const PageLayout: FC<PropsWithChildren<{
    title: string;
    withBackButton?: boolean;
    padding?: boolean;
    headerImage?: boolean;
    fab?: ReactNode
}>> = ({
  children, title, withBackButton, padding, headerImage, fab,
}) => (
  <IonPage>
    <IonHeader>
      <IonToolbar>
        {withBackButton ? (
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
        ) : (
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
        )}
        <IonTitle>{title}</IonTitle>
      </IonToolbar>
    </IonHeader>
    <IonContent fullscreen>
      {headerImage ? (
        <img src="/ff14_funkit/bg_tw.jpg" alt="ff14 cover" />
      ) : null}
      <div className={padding ? 'ion-padding-start ion-padding-end ' : ''} style={{
        marginBottom: '100px'
      }}>
        {children}

      <ins className="adsbygoogle"
         style={{display:"block"}}
         data-ad-client="ca-pub-6091198629319043"
         data-ad-slot="3545148712"
         data-ad-format="auto"
         data-full-width-responsive="true"></ins>
      </div>
    </IonContent>
    {fab || null}
  </IonPage>
);
