import React, {
  FC, useCallback, useMemo, useState,
} from 'react';
import {
  IonButton, IonCol, IonGrid, IonHeader, IonItem, IonItemGroup, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonList, IonListHeader, IonModal, IonRow, IonSelect, IonSelectOption,
} from '@ionic/react';
import { useMediaQueries } from '@react-hook/media-query';
import { useLootRecordHook } from '../hooks/roomRecord';
import { LootRecord, LootStatus, LootType } from '../interfaces';
import { getJobNameById } from '../libs/jobName';

export type LootListProps = {
  record: LootRecord;
  items: Array<{
    label: string;
    id: keyof LootStatus
    category: 'arms' | 'armor' | 'accessories' | 'other';
  }>;
  updateLootData: (records: Pick<LootRecord, 'loots_dps_1' | 'loots_dps_2' | 'loots_dps_3' | 'loots_dps_4' | 'loots_healer_1' | 'loots_healer_2' | 'loots_tank_1' | 'loots_tank_2'>) => void
}

const Item: FC<{
  item:{
    label: string;
    id: keyof LootStatus
  }
  record: LootRecord;
  updateLootStatus: (jobId: keyof LootRecord, itemId: keyof LootStatus, newStatus: LootType) => void;
}> = ({ item, record, updateLootStatus }) => {
  const [open, isOpen] = useState(false);
  const {
    label,
    id,
  } = item;
  return (
    <>
      <IonItemSliding>
        <IonItem>
          <IonLabel>
            {label}
            装備
          </IonLabel>
          <IonButton onClick={() => isOpen(true)}>獲得状況</IonButton>
        </IonItem>
        <IonItemOptions side="end">
          <IonItemOption color="danger">不要</IonItemOption>
        </IonItemOptions>
      </IonItemSliding>
      <IonModal isOpen={open}>
        <IonHeader>
          {label}
          獲得状況
        </IonHeader>
        <LootListItems id={id} record={record} updateLootStatus={updateLootStatus} />
        <IonButton onClick={() => isOpen(false)}>閉じる</IonButton>
      </IonModal>
    </>
  );
};
const LootListItems: FC<{
  record: LootRecord;
  id: keyof LootStatus;
  updateLootStatus: (jobId: keyof LootRecord, itemId: keyof LootStatus, newStatus: LootType) => void;
}> = ({ record, id, updateLootStatus }) => (
  <IonList>
    <LootListItem
      type={record.loots_tank_1[id] as LootType}
      itemId={id}
      updateLootStatus={updateLootStatus}
      playerName={record.loots_tank_1.player_name}
      jobNameId={record.loots_tank_1.job_name}
      jobId="loots_tank_1"
      job="タンク 1"
    />
    <LootListItem
      type={record.loots_tank_2[id] as LootType}
      itemId={id}
      updateLootStatus={updateLootStatus}
      playerName={record.loots_tank_2.player_name}
      jobNameId={record.loots_tank_2.job_name}
      jobId="loots_tank_2"
      job="タンク 2"
    />
    <LootListItem
      type={record.loots_healer_1[id] as LootType}
      itemId={id}
      updateLootStatus={updateLootStatus}
      playerName={record.loots_healer_1.player_name}
      jobNameId={record.loots_healer_1.job_name}
      jobId="loots_healer_1"
      job="ヒーラー 1"
    />
    <LootListItem
      type={record.loots_healer_2[id] as LootType}
      itemId={id}
      updateLootStatus={updateLootStatus}
      playerName={record.loots_healer_2.player_name}
      jobNameId={record.loots_healer_2.job_name}
      jobId="loots_healer_2"
      job="ヒーラー 2"
    />
    <LootListItem
      type={record.loots_dps_1[id] as LootType}
      itemId={id}
      updateLootStatus={updateLootStatus}
      playerName={record.loots_dps_1.player_name}
      jobNameId={record.loots_dps_1.job_name}
      jobId="loots_dps_1"
      job="DPS 1"
    />
    <LootListItem
      type={record.loots_dps_2[id] as LootType}
      itemId={id}
      updateLootStatus={updateLootStatus}
      playerName={record.loots_dps_2.player_name}
      jobNameId={record.loots_dps_2.job_name}
      jobId="loots_dps_2"
      job="DPS 2"
    />
    <LootListItem
      type={record.loots_dps_3[id] as LootType}
      itemId={id}
      updateLootStatus={updateLootStatus}
      playerName={record.loots_dps_3.player_name}
      jobNameId={record.loots_dps_3.job_name}
      jobId="loots_dps_3"
      job="DPS 3"
    />
    <LootListItem
      type={record.loots_dps_4[id] as LootType}
      itemId={id}
      updateLootStatus={updateLootStatus}
      playerName={record.loots_dps_4.player_name}
      jobNameId={record.loots_dps_4.job_name}
      jobId="loots_dps_4"
      job="DPS 4"
    />
  </IonList>
);

const LootListItem: FC<{
  type: LootType;
  job: string;
  jobId: keyof LootRecord;
  playerName?: string;
  jobNameId?: string;
  itemId: keyof LootStatus,
  updateLootStatus: (jobId: keyof LootRecord, itemId: keyof LootStatus, newStatus: LootType) => void;
}> = ({
  type, job, jobId, itemId, updateLootStatus, playerName, jobNameId,
}) => {
  const onChange = useCallback((event: CustomEvent) => {
    if (type === event.detail.value) return;
    updateLootStatus(jobId, itemId, event.detail.value);
  }, [updateLootStatus, jobId, itemId, type]);
  const jobName = useMemo(() => {
    if (!jobNameId) return null;
    return getJobNameById(jobNameId);
  }, [jobNameId]);
  return (
    <IonItem>
      <IonLabel>
        {playerName ? (
          <>
            <span>{playerName}</span>
            <br />
            <small>
              {jobName || job}
            </small>
          </>
        ) : (
          <>
            {jobName || job}
          </>
        )}
      </IonLabel>
      <IonSelect
        value={type}
        onIonChange={onChange}
      >
        <IonSelectOption value="have">Have (獲得済み)</IonSelectOption>
        <IonSelectOption value="need">Need</IonSelectOption>
        <IonSelectOption value="greed">Greed</IonSelectOption>
        <IonSelectOption value="pass">Pass</IonSelectOption>
      </IonSelect>
    </IonItem>
  );
};

const items: Array<{
    label: string;
    id: keyof LootStatus;
    category: 'arms' | 'armor' | 'accessories' | 'other';
  }> = [{
    label: '頭',
    id: 'head',
    category: 'armor',
  }, {
    label: '胴',
    id: 'body',
    category: 'armor',
  }, {
    label: '帯',
    id: 'belt',
    category: 'armor',
  }, {
    label: '脚',
    id: 'legs',
    category: 'armor',
  }, {
    label: '足',
    id: 'feet',
    category: 'armor',
  },
  {
    label: '耳飾り',
    id: 'earrings',
    category: 'accessories',
  }, {
    label: '首飾り',
    id: 'necklace',
    category: 'accessories',
  }, {
    label: '腕輪',
    id: 'bracelet',
    category: 'accessories',
  }, {
    label: '指輪',
    id: 'ring',
    category: 'accessories',
  }, {
    label: '武器箱',
    id: 'arm_box',
    category: 'arms',
  }, {
    label: '武器',
    id: 'arm_1',
    category: 'arms',
  }, {
    /*
    label: '武器（第二希望）',
    id: 'arm_2',
  }, { */
    label: 'マウント',
    id: 'mount',
    category: 'other',
  }];

export const LootLists: FC<LootListProps> = ({ items, record, updateLootData }) => {
  const { matches } = useMediaQueries({
    screen: 'screen',
    width: '(min-width: 400px)',
  });
  const updateLootStatus = useCallback((jobId: keyof LootRecord, itemId: keyof LootStatus, newStatus: LootType) => {
    updateLootData({
      ...record,
      // eslint-disable-next-line prefer-object-spread
      [jobId]: Object.assign({}, record[jobId], { [itemId]: newStatus }),
    });
  }, [updateLootData, record]);
  const {
    accessories, armors, others, arms,
  } = useMemo(() => ({
    accessories: items.filter(({ category }) => category === 'accessories'),
    armors: items.filter(({ category }) => category === 'armor'),
    others: items.filter(({ category }) => category === 'other'),
    arms: items.filter(({ category }) => category === 'arms'),
  }), [items]);
  if (matches.width) {
    return (
      <>
        <IonGrid>
          <IonRow>
            <IonListHeader>
              <IonLabel>武器</IonLabel>
            </IonListHeader>
            {arms.map((item, i) => (
              <IonCol key={`pc-arms-${i}`} size="6" sizeMd="4" sizeXl="3" sizeSm="12" sizeXs="12">
                <IonItemGroup>
                  <IonListHeader>
                    {item.label}
                    {' '}
                    獲得状況
                  </IonListHeader>
                  <LootListItems id={item.id} record={record} updateLootStatus={updateLootStatus} />
                </IonItemGroup>
              </IonCol>
            ))}
          </IonRow>
          <IonRow>
            <IonListHeader>
              <IonLabel>装備</IonLabel>
            </IonListHeader>
            {armors.map((item, i) => (
              <IonCol key={`pc-armors-${i}`} size="6" sizeMd="4" sizeXl="3" sizeSm="12" sizeXs="12">
                <IonItemGroup>
                  <IonListHeader>
                    {item.label}
                    {' '}
                    獲得状況
                  </IonListHeader>
                  <LootListItems id={item.id} record={record} updateLootStatus={updateLootStatus} />
                </IonItemGroup>
              </IonCol>
            ))}
          </IonRow>
          <IonRow>

            <IonListHeader>
              <IonLabel>アクセサリー</IonLabel>
            </IonListHeader>
            {accessories.map((item, i) => (
              <IonCol key={`pc-accessories-${i}`} size="6" sizeMd="4" sizeXl="3" sizeSm="12" sizeXs="12">
                <IonItemGroup>
                  <IonListHeader>
                    {item.label}
                    {' '}
                    獲得状況
                  </IonListHeader>
                  <LootListItems id={item.id} record={record} updateLootStatus={updateLootStatus} />
                </IonItemGroup>
              </IonCol>
            ))}
          </IonRow>
          <IonRow>
            <IonListHeader>
              <IonLabel>その他</IonLabel>
            </IonListHeader>
            {others.map((item, i) => (
              <IonCol key={`pc-other-${i}`} size="6" sizeMd="4" sizeXl="3" sizeSm="12" sizeXs="12">
                <IonItemGroup>
                  <IonListHeader>
                    {item.label}
                    {' '}
                    獲得状況
                  </IonListHeader>
                  <LootListItems id={item.id} record={record} updateLootStatus={updateLootStatus} />
                </IonItemGroup>
              </IonCol>
            ))}
          </IonRow>
        </IonGrid>

      </>
    );
  }
  return (
    <>
      <IonItemGroup>
        <IonList>

          <IonListHeader>
            <IonLabel>武器</IonLabel>
          </IonListHeader>
          {arms.map((item, i) => <Item item={item} record={record} key={i} updateLootStatus={updateLootStatus} />)}
          <IonListHeader>
            <IonLabel>装備</IonLabel>
          </IonListHeader>
          {armors.map((item, i) => <Item item={item} record={record} key={i} updateLootStatus={updateLootStatus} />)}

          <IonListHeader>
            <IonLabel>アクセサリー</IonLabel>
          </IonListHeader>
          {accessories.map((item, i) => <Item item={item} record={record} key={i} updateLootStatus={updateLootStatus} />)}

          <IonListHeader>
            <IonLabel>その他</IonLabel>
          </IonListHeader>
          {others.map((item, i) => <Item item={item} record={record} key={i} updateLootStatus={updateLootStatus} />)}

        </IonList>
      </IonItemGroup>
    </>
  );
};

export const ContainerLootLists: FC<{
    record: LootRecord;
}> = ({ record }) => {
  const { updateLootData } = useLootRecordHook(record);
  if (!record) return null;
  return <LootLists record={record} items={items} updateLootData={updateLootData} />;
};
