import React, { FC } from 'react';
import { IonItem, IonLabel, IonList, IonButton, IonChip } from '@ionic/react';
import './Home.css';
import { useRoomLoader } from '../hooks/listRooms';
import { PageLayout } from '../components/Layouts/PageLayout';

const Home: FC = () => {
  const rooms = useRoomLoader();
  return (
    <PageLayout title="参加中のルーム" headerImage>
      {rooms.length > 0 ? (
        <IonList>
          {rooms.map((room) => (
            <IonItem lines="full" key={room.id} routerLink={`/rooms/${room.id}`}>
              <IonChip slot="start">Room</IonChip>
              <IonLabel>
                {room.name}
              </IonLabel>
            </IonItem>
          ))}
        </IonList>
      ) : (
        <div className="ion-padding">
          <p>参加したことのあるルームがありませんでした。</p>
          <p><IonButton href="/create_new_room">ルームを作成する</IonButton></p>
        </div>
      )}
    </PageLayout>
  );
};

export default Home;
