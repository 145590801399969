import React, { FC, memo } from 'react';
import { Link } from 'react-router-dom';
import { PageLayout } from '../components/Layouts/PageLayout';
import { IonList, IonItem, IonListHeader, IonLabel, IonText } from '@ionic/react';

export const PageAbout:FC = memo(() => (
  <PageLayout title="このアプリについて" headerImage>
    <IonList>
      <IonListHeader><strong>About</strong></IonListHeader>
      <IonItem lines="full">
        <IonLabel>このアプリは、FF14の固定パーティでのロット状況を共有するアプリです。</IonLabel>
      </IonItem>
    </IonList>

    <IonList>
      <IonListHeader><strong>使い方</strong></IonListHeader>
      <IonItem>
        <IonLabel>
          「
          <Link to="create_new_room">固定作成</Link>
          」からルームを作成します
        </IonLabel>
      </IonItem>
      <IonItem>
        <IonLabel>
          ルームのURLをメンバーに共有します
        </IonLabel>
      </IonItem>
      <IonItem>
        <IonLabel>
          自分のジョブで、必要な装備について「Need」にステータスを変更します
          <ul>
            <li>Have: 獲得済み</li>
            <li>Need: 獲得希望</li>
            <li>Greed: 他にNeedを出している人がいなければ欲しい</li>
            <li>Pass: 不要</li>
          </ul>
        </IonLabel>
      </IonItem>
      <IonItem lines="full">
        <IonLabel>
          装備が手に入った場合は、該当する装備について「Have」にステータスを変更します
        </IonLabel>
      </IonItem>
    </IonList>

    <IonList>
      <IonListHeader><strong>注意事項</strong></IonListHeader>
      <IonItem>
        <IonLabel>このアプリケーションは現在α版です。未対応のバグが発生するおそれがありますがご了承ください</IonLabel>
      </IonItem>
      <IonItem>
        <IonLabel>バグ修正・機能追加のために予告なくアプリケーションを更新・停止する可能性があります</IonLabel>
      </IonItem>
      <IonItem>
        <IonLabel>DBなどのサーバー負荷状況によって、ユーザーあたりのルーム作成数などを制限する場合がありますので、ご了承ください</IonLabel>
      </IonItem>
      <IonItem>
        <IonLabel>ルームのデータは、<strong>作成時から半年後</strong>に自動で消去されます</IonLabel>
      </IonItem>
      <IonItem>
        <IonLabel>アクセス数を確認するために、Google Analyticsを利用しています</IonLabel>
      </IonItem>
    </IonList>

    <IonList>
      <IonListHeader><strong>ライセンス・商標について</strong></IonListHeader>
      <IonItem>
        <IonText>
          <p>
            このアプリケーションで使用している画像・データの一部は、
            <a
              href="https://jp.finalfantasyxiv.com/lodestone/special/fankit/icon/"
              target="_blank"
              rel="noopener noreferrer"
            >
              「ファイナルファンタジー14 ファンキット」
            </a>
            を利用しています。
            <br />
          </p>
          <p>
            記載されている会社名･製品名･システム名などは各社の商標または登録商標です。
            <br />
            <small>Copyright (C) 2010 - 2020 SQUARE ENIX CO., LTD. All Rights Reserved.</small>
          </p>
        </IonText>
      </IonItem>
    </IonList>
  </PageLayout>
));
