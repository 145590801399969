import React from 'react';
import { Link, Redirect, Route } from 'react-router-dom';
import {
  IonIcon, IonLabel,
  IonApp, IonRouterOutlet, IonSplitPane, IonMenu, IonContent, IonList, IonListHeader, IonHeader, IonToolbar, IonTitle, IonItem,
} from '@ionic/react';

import {
  home, document, informationCircle,
} from 'ionicons/icons';

import { IonReactRouter } from '@ionic/react-router';
import {GTag} from '@deptno/gtag-react'
import { ContainerRouteRooms } from './routes/Rooms';
import { RouteMain } from './routes/Main';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import 'share-api-polyfill';

const App: React.FC = () => (
  <IonApp>
    <IonReactRouter>
      <IonSplitPane contentId="main">
        <IonMenu type="overlay" disabled={false} contentId="main">
          <IonHeader>
            <IonToolbar>
              <IonTitle>
                <Link to="/">FF14固定ロット管理(α版)</Link>
              </IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent forceOverscroll={false}>
            <IonList lines="none">
              <IonListHeader>Menu</IonListHeader>
              <IonItem routerLink="/">
                <IonIcon slot="start" icon={home} />
                <IonLabel>Home</IonLabel>
              </IonItem>
              <IonItem routerLink="/create_new_room">
                <IonIcon slot="start" icon={document} />
                <IonLabel>固定作成</IonLabel>
              </IonItem>
              <IonItem routerLink="/about">
                <IonIcon slot="start" icon={informationCircle} />
                <IonLabel>About</IonLabel>
              </IonItem>
            </IonList>
          </IonContent>
        </IonMenu>

        <IonRouterOutlet id="main">
          <Route path="/" component={RouteMain} />
          <Redirect to="/" from="/rooms" exact />
          <Route path="/rooms/:roomId" component={ContainerRouteRooms} />
        </IonRouterOutlet>
      </IonSplitPane>
      <GTag id='G-RWPD5DWNWX' />
    </IonReactRouter>
  </IonApp>
);

export default App;
