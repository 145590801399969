import {
  IonButton,
  IonInput, IonItem, IonItemDivider,
  IonLabel, IonList, IonListHeader, IonSelect, IonSelectOption,
} from '@ionic/react';
import React, {
  FC, useCallback, useMemo, useState,
} from 'react';
import { useLootRecordHook } from '../hooks/roomRecord';
import { LootRecord } from '../interfaces';

export type ListMembersProps = {
    record: LootRecord;
    updateLootData: (records: Pick<LootRecord, 'loots_dps_1' | 'loots_dps_2' | 'loots_dps_3' | 'loots_dps_4' | 'loots_healer_1' | 'loots_healer_2' | 'loots_tank_1' | 'loots_tank_2'>) => void
}

export const JobSelector: FC<{
    roleName: 'tank' | 'dps' | 'healer';
    jobName: string;
    jobId: keyof LootRecord;
    currentJob: string;
    updateLootStatus: (jobId: keyof LootRecord, itemId: 'player_name' | 'job_name', value: string) => void;
}> = ({
  jobName, currentJob, updateLootStatus, jobId,
  roleName,
}) => {
  const onChange = useCallback((event: CustomEvent) => {
    if (currentJob === event.detail.value) return;
    updateLootStatus(jobId, 'job_name', event.detail.value);
  }, [updateLootStatus, jobId, currentJob]);
  const Options = useMemo(() => {
    if (roleName === 'tank') {
      return (
        <>
          <IonSelectOption value="dark_knight">暗黒騎士</IonSelectOption>
          <IonSelectOption value="gun_breaker">ガンブレイカー</IonSelectOption>
          <IonSelectOption value="warrior">戦士</IonSelectOption>
          <IonSelectOption value="paladin">ナイト</IonSelectOption>
        </>
      );
    } if (roleName === 'healer') {
      return (
        <>
          <IonSelectOption value="astrologia">占星術師</IonSelectOption>
          <IonSelectOption value="scholar">学者</IonSelectOption>
          <IonSelectOption value="shite_mage">白魔道士</IonSelectOption>
        </>
      );
    }
    return (
      <>
        <IonSelectOption value="bard">吟遊詩人</IonSelectOption>
        <IonSelectOption value="black_mage">黒魔道士</IonSelectOption>
        <IonSelectOption value="dancer">踊り子</IonSelectOption>
        <IonSelectOption value="dragoon">竜騎士</IonSelectOption>
        <IonSelectOption value="machinist">機工師</IonSelectOption>
        <IonSelectOption value="monk">モンク</IonSelectOption>
        <IonSelectOption value="ninja">忍者</IonSelectOption>
        <IonSelectOption value="red_merge">赤魔道士</IonSelectOption>
        <IonSelectOption value="samurai">侍</IonSelectOption>
        <IonSelectOption value="summoner">召喚師</IonSelectOption>
      </>
    );
  }, [roleName]);
  return (
    <>
      <IonItemDivider>
        <IonLabel>{jobName}</IonLabel>
      </IonItemDivider>
      <IonItem lines="none">
        <IonSelect
          slot="end"
          value={currentJob}
          onIonChange={onChange}
        >
          {Options}
        </IonSelect>
      </IonItem>
    </>
  );
};

export const PlayerName: FC<{
    jobName: string;
    jobId: keyof LootRecord;
    currentName: string;
    updateLootStatus: (jobId: keyof LootRecord, itemId: 'player_name' | 'job_name', value: string) => void;
}> = ({
  jobName, currentName, updateLootStatus, jobId,
}) => {
  const [hasChanged, isChanged] = useState(false);
  const [name, setName] = useState(currentName || '');
  const onChange = useCallback((event: CustomEvent) => {
    setName(event.detail.value);
    isChanged(true);
  }, [setName]);
  const onEnter = useCallback((e: React.KeyboardEvent<HTMLIonInputElement>) => {
    if (e.key.toLocaleLowerCase() !== 'enter') return;
    updateLootStatus(jobId, 'player_name', name);
  }, [name, updateLootStatus, jobId]);
  const onSubmit = useCallback(() => {
    updateLootStatus(jobId, 'player_name', name);
  }, [name, updateLootStatus, jobId]);
  return (
    <IonItem>
      <IonLabel slot="start">{jobName}</IonLabel>
      <IonInput
        value={name}
        placeholder="プレイヤー名を入力してください"
        onIonChange={onChange}
        onKeyDown={onEnter}
      />
      {hasChanged ? (
        <IonButton slot="end" onClick={onSubmit}>Save</IonButton>
      ) : null}
    </IonItem>
  );
};

export const ListMembers: FC<ListMembersProps> = ({
  record, updateLootData,
}) => {
  const updateLootStatus = useCallback((jobId: keyof LootRecord, itemId: 'player_name' | 'job_name', value: string) => {
    updateLootData({
      ...record,
      // eslint-disable-next-line prefer-object-spread
      [jobId]: Object.assign({}, record[jobId], { [itemId]: value }),
    });
  }, [updateLootData, record]);
  return (
    <>
      <IonListHeader>
        <IonLabel>プレイヤー</IonLabel>
      </IonListHeader>
      <IonList>
        <PlayerName
          jobName="タンク 1"
          jobId="loots_tank_1"
          currentName={record.loots_tank_1.player_name}
          updateLootStatus={updateLootStatus}
        />
        <PlayerName
          jobName="タンク 2"
          jobId="loots_tank_2"
          currentName={record.loots_tank_2.player_name}
          updateLootStatus={updateLootStatus}
        />
        <PlayerName
          jobName="ヒーラー 1"
          jobId="loots_healer_1"
          currentName={record.loots_healer_1.player_name}
          updateLootStatus={updateLootStatus}
        />
        <PlayerName
          jobName="ヒーラー 2"
          jobId="loots_healer_2"
          currentName={record.loots_healer_2.player_name}
          updateLootStatus={updateLootStatus}
        />
        <PlayerName
          jobName="DPS 1"
          jobId="loots_dps_1"
          currentName={record.loots_dps_1.player_name}
          updateLootStatus={updateLootStatus}
        />
        <PlayerName
          jobName="DPS 2"
          jobId="loots_dps_2"
          currentName={record.loots_dps_2.player_name}
          updateLootStatus={updateLootStatus}
        />
        <PlayerName
          jobName="DPS 3"
          jobId="loots_dps_3"
          currentName={record.loots_dps_3.player_name}
          updateLootStatus={updateLootStatus}
        />
        <PlayerName
          jobName="DPS 4"
          jobId="loots_dps_4"
          currentName={record.loots_dps_4.player_name}
          updateLootStatus={updateLootStatus}
        />
      </IonList>
      <IonItemDivider />

      <IonListHeader>
        <IonLabel>ジョブ</IonLabel>
      </IonListHeader>
      <IonList>
        <JobSelector
          roleName="tank"
          jobName="タンク 1"
          jobId="loots_tank_1"
          currentJob={record.loots_tank_1.job_name}
          updateLootStatus={updateLootStatus}
        />
        <JobSelector
          roleName="tank"
          jobName="タンク 2"
          jobId="loots_tank_2"
          currentJob={record.loots_tank_2.job_name}
          updateLootStatus={updateLootStatus}
        />
        <JobSelector
          roleName="healer"
          jobName="ヒーラー 1"
          jobId="loots_healer_1"
          currentJob={record.loots_healer_1.job_name}
          updateLootStatus={updateLootStatus}
        />
        <JobSelector
          roleName="healer"
          jobName="ヒーラー 2"
          jobId="loots_healer_2"
          currentJob={record.loots_healer_2.job_name}
          updateLootStatus={updateLootStatus}
        />
        <JobSelector
          roleName="dps"
          jobName="DPS 1"
          jobId="loots_dps_1"
          currentJob={record.loots_dps_1.job_name}
          updateLootStatus={updateLootStatus}
        />
        <JobSelector
          roleName="dps"
          jobName="DPS 2"
          jobId="loots_dps_2"
          currentJob={record.loots_dps_2.job_name}
          updateLootStatus={updateLootStatus}
        />
        <JobSelector
          roleName="dps"
          jobName="DPS 3"
          jobId="loots_dps_3"
          currentJob={record.loots_dps_3.job_name}
          updateLootStatus={updateLootStatus}
        />
        <JobSelector
          roleName="dps"
          jobName="DPS 4"
          jobId="loots_dps_4"
          currentJob={record.loots_dps_4.job_name}
          updateLootStatus={updateLootStatus}
        />
      </IonList>
    </>
  );
};

export const ContainerListMembers: FC<{
    record: LootRecord;
}> = ({ record }) => {
  const { updateLootData } = useLootRecordHook(record);
  if (!record) return null;
  return (
    <ListMembers record={record} updateLootData={updateLootData} />
  );
};
