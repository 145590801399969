import { useCallback, useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import Observable, { ZenObservable } from 'zen-observable-ts';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import {
  LootRecord, LootStatus,
} from '../interfaces';
import { createRandomStrings } from '../libs/randomString';

const sleep = (msec: number) => new Promise(resolve => setTimeout(resolve, msec));
/**
 * Roomのデータ取得と更新系
 * @param roomId
 */
export const useLootRecordItem = (roomId: string) => {
  const [record, setRecord] = useState<LootRecord | null>(null);
  const [retryCount, updateRetryCount] = useState(0)
  const [processStatus, setProcessStatus] = useState<'' | 'loading' | 'complete' | 'not_found'>('');
  const setRecordItem = useCallback((item: any) => {
    setRecord({
      ...item,
      loots_dps_1: convertFromJSON(item.loots_dps_1),
      loots_dps_2: convertFromJSON(item.loots_dps_2),
      loots_dps_3: convertFromJSON(item.loots_dps_3),
      loots_dps_4: convertFromJSON(item.loots_dps_4),
      loots_healer_1: convertFromJSON(item.loots_healer_1),
      loots_healer_2: convertFromJSON(item.loots_healer_2),
      loots_tank_1: convertFromJSON(item.loots_tank_1),
      loots_tank_2: convertFromJSON(item.loots_tank_2),
    });
  }, [setRecord]);
  const fetchRoom = useCallback(async (id: string): Promise<boolean> => {
    const { data } = await API.graphql(graphqlOperation(`query getRoom {
        getFF14LootManager(room_id: "${id}") {
          invite_code
          loots_dps_1
          loots_dps_2
          loots_dps_3
          loots_dps_4
          loots_healer_1
          loots_healer_2
          loots_tank_1
          loots_tank_2
          owner_id
          room_id
          room_name
          ttl
        }
      }`)) as GraphQLResult<{
          getFF14LootManager: LootRecord
        }
      >;
      if (!data || !data.getFF14LootManager) {
        console.log(retryCount)
        if (retryCount < 10) {
          await sleep(1000)
          updateRetryCount(retryCount + 1)
          return fetchRoom(id)
        } else {
          setProcessStatus('not_found');
          return false;
        }
      }
      const result = data.getFF14LootManager;
      setRecordItem(result);
      setProcessStatus('complete');
      return true;
  }, [setRecordItem,setProcessStatus, retryCount, updateRetryCount])
  const subscribeRoom = useCallback((id: string): ZenObservable.Subscription => {
    const query = API.graphql(
      graphqlOperation(`subscription MySubscription {
          onUpdateFF14LootManager(room_id: "${id}") {
            invite_code
            loots_dps_1
            loots_dps_2
            loots_dps_3
            loots_dps_4
            loots_healer_1
            loots_healer_2
            loots_tank_1
            loots_tank_2
            owner_id
            room_id
            room_name
            ttl
          }
        }
        `),
    ) as Observable<any>;
    const subscription = query.subscribe({
      next: (result) => {
        setRecordItem(result.value.data.onUpdateFF14LootManager);
      },
      error: (e) => {
        console.error(e);
      },
    });
    return subscription;
  }, [setRecordItem])
  useEffect(() => {
    let subscription: ZenObservable.Subscription;
    setProcessStatus('loading');
    console.log(roomId);
    (async () => {
      const fetchResult = await fetchRoom(roomId)
      if (!fetchResult) return;
      subscription = await subscribeRoom(roomId)
    })();
    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
      setProcessStatus('');
    };
  }, [roomId, fetchRoom, subscribeRoom, setProcessStatus]);

  return {
    record,
    processStatus,
  };
};

export const convertToJSON = (data: string | LootStatus): string => {
  if (typeof data === 'string') return data;
  return JSON.stringify(data);
};
export const convertFromJSON = (data: string | LootStatus): LootStatus => {
  if (typeof data !== 'string') return data;
  return JSON.parse(data);
};

export const useLootRecordHook = (item?: LootRecord | null) => {
  const [updateResult, setUpdateResult] = useState<any>(null);
  const [onUpdating, isUpdatingInProgress] = useState(false);
  const updateRecord = useCallback(async (record: LootRecord) => {
    if (onUpdating) return;
    const input = {
      ...record,
      loots_dps_1: convertToJSON(record.loots_dps_1),
      loots_dps_2: convertToJSON(record.loots_dps_2),
      loots_dps_3: convertToJSON(record.loots_dps_3),
      loots_dps_4: convertToJSON(record.loots_dps_4),
      loots_healer_1: convertToJSON(record.loots_healer_1),
      loots_healer_2: convertToJSON(record.loots_healer_2),
      loots_tank_1: convertToJSON(record.loots_tank_1),
      loots_tank_2: convertToJSON(record.loots_tank_2),
    };
    try {
      isUpdatingInProgress(true);
      const { data } = await API.graphql(graphqlOperation(`
        mutation updateFF14LootManager($updateff14lootmanagerinput: UpdateFF14LootManagerInput!) {
          updateFF14LootManager(input: $updateff14lootmanagerinput) {
            room_id
            owner_id
            loots_tank_1
            loots_tank_2
            loots_healer_1
            loots_healer_2
            loots_dps_1
            loots_dps_2
            loots_dps_3
            loots_dps_4
            invite_code
            room_name
          }
        }
        `, {
        updateff14lootmanagerinput: input,
      })) as GraphQLResult<any>;
      setUpdateResult(data);
    } catch (e) {
      console.error(e);
      setUpdateResult(e);
    } finally {
      isUpdatingInProgress(false);
    }
  }, [setUpdateResult, onUpdating, isUpdatingInProgress]);
  const updateRoomName = useCallback((roomName: string) => {
    if (!item) return;
    updateRecord({
      ...item,
      room_name: roomName,
    });
  }, [item, updateRecord]);
  const updateInviteCode = useCallback(() => {
    if (!item) return;
    updateRecord({
      ...item,
      invite_code: createRandomStrings(),
    });
  }, [item, updateRecord]);
  const updateLootData = useCallback((records: Pick<LootRecord, 'loots_dps_1' | 'loots_dps_2' | 'loots_dps_3' | 'loots_dps_4' | 'loots_healer_1' | 'loots_healer_2' | 'loots_tank_1' | 'loots_tank_2'>) => {
    if (!item) return;
    updateRecord({
      ...item,
      ...records,
    });
  }, [item, updateRecord]);
  return {
    updateRoomName,
    updateInviteCode,
    updateResult,
    updateLootData,
  };
};
