import React, { FC, useCallback } from 'react';
import {
  IonFab, IonFabButton, IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs, IonSpinner,
} from '@ionic/react';
import { Route, RouteComponentProps, withRouter } from 'react-router';

import { globe, list, person } from 'ionicons/icons';
import { Plugins } from '@capacitor/core';
import { PageLayout } from '../components/Layouts/PageLayout';
import { ContainerLootLists } from '../container/LootList';
import { useRoomRecorder } from '../hooks/listRooms';
import { useLootRecordItem } from '../hooks/roomRecord';
import { ContainerListMembers } from '../container/ListMembers';

const { Share } = Plugins;
export const RouteRooms: FC<RouteComponentProps<{
    roomId: string
}>> = (
  {
    match: {
      params: {
        roomId,
      },
    },
  },
) => {
  const {
    record,
    processStatus,
  } = useLootRecordItem(roomId);
  useRoomRecorder(roomId);
  const useShareTheURL = useCallback(() => {
    Share.share({
      title: '固定ロット管理アプリ(α)',
      text: 'FF14の固定でロットを管理するためのルームを作りました！',
      url: window.location.href,
      dialogTitle: 'Share the room',
    });
  }, []);
  if (!record) {
    return (
      <PageLayout
        title={processStatus === 'loading' ? 'Loading...' : 'Error: Can not get the room'}
      >
        {processStatus === 'loading' ? (
          <div className="ion-text-center ion-padding">
            <IonSpinner />
          </div>
        ) : (
          <>
            <p>Failed to load the room data</p>
            <pre><code>{JSON.stringify({ processStatus }, null, 2)}</code></pre>
          </>
        )}
      </PageLayout>
    );
  }
  return (
    <>
      <IonTabs>
        <IonRouterOutlet>
          <Route
            path="/rooms/:roomId"
            render={() => (
              <PageLayout
                title={`Room: ${record.room_name}`}
                fab={(
                  <IonFab vertical="bottom" slot="fix" horizontal="end">
                    <IonFabButton>
                      <IonIcon icon={globe} onClick={useShareTheURL} />
                    </IonFabButton>
                  </IonFab>
)}
              >
                <ContainerLootLists record={record} />
              </PageLayout>
            )}
            exact
          />
          <Route
            path="/rooms/:roomId/members"
            render={() => (
              <PageLayout
                title={`Room: ${record.room_name} メンバー`}
                fab={(
                  <IonFab vertical="bottom" slot="fix" horizontal="end">
                    <IonFabButton>
                      <IonIcon icon={globe} onClick={useShareTheURL} />
                    </IonFabButton>
                  </IonFab>
)}
              >
                <ContainerListMembers record={record} />
              </PageLayout>
            )}
            exact
          />
        </IonRouterOutlet>
        <IonTabBar slot="bottom">
          <IonTabButton tab="list" href={`/rooms/${roomId}`}>
            <IonIcon icon={list} />
            <IonLabel>ロット表</IonLabel>
          </IonTabButton>
          <IonTabButton tab="members" href={`/rooms/${roomId}/members`}>
            <IonIcon icon={person} />
            <IonLabel>メンバー</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
    </>
  );
};

export const ContainerRouteRooms = withRouter(RouteRooms);
