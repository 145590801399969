export const getJobNameById = (id: string): string => {
  if (id === 'dark_knight') return '暗黒騎士';
  if (id === 'gun_breaker') return 'ガンブレイカー';
  if (id === 'warrior') return '戦士';
  if (id === 'paladin') return 'ナイト';
  if (id === 'astrologia') return '占星術師';
  if (id === 'scholar') return '学者';
  if (id === 'shite_mage') return '白魔道士';
  if (id === 'bard') return '吟遊詩人';
  if (id === 'black_mage') return '黒魔道士';
  if (id === 'dancer') return '踊り子';
  if (id === 'dragoon') return '竜騎士';
  if (id === 'machinist') return '機工師';
  if (id === 'monk') return 'モンク';
  if (id === 'ninja') return '忍者';
  if (id === 'red_merge') return '赤魔道士';
  if (id === 'samurai') return '侍';
  if (id === 'summoner') return '召喚師';
  throw new Error('Un supported job id');
};
