import { useEffect } from 'react';
import { useLootRecordItem } from './roomRecord';

const { localStorage } = window;
export const useRoomRecorder = (roomId: string): void => {
  const {
    record,
    processStatus,
  } = useLootRecordItem(roomId);
  const roomName = record ? record.room_name : null;
  useEffect(() => {
    if (!localStorage) return;
    if (!['complete', 'not_found'].includes(processStatus)) return;
    const roomIdsString = localStorage.getItem('loot_manager_rooms');
    const rooms = roomIdsString ? JSON.parse(roomIdsString) as Array<{id: string, name: string}> : [];
    const roomIds = rooms.map(({ id }) => id);
    if (processStatus === 'not_found' && roomIds.includes(roomId)) {
      const newRoomLists = rooms.filter(({ id }) => id !== roomId);
      localStorage.setItem('loot_manager_rooms', JSON.stringify(newRoomLists));
    } else if (processStatus === 'complete' && !roomIds.includes(roomId) && roomName) {
      rooms.push({ id: roomId, name: roomName });
      localStorage.setItem('loot_manager_rooms', JSON.stringify(rooms));
    }
  }, [roomName, roomId, processStatus]);
};

export const useRoomLoader = () => {
  const rooms = window.localStorage?.getItem('loot_manager_rooms');
  if (!rooms) return [];
  return JSON.parse(rooms) as Array<{id: string; name: string}>;
};
